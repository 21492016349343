import firebase from '@firebase/app';
import '@firebase/auth';
import '@firebase/firestore';
import '@firebase/analytics';
import '@firebase/functions';

// const isDev = process.env.NODE_ENV === 'development';
const isDev = false;
const config = isDev
  ? JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG)
  : {
      apiKey: 'AIzaSyC5N3NcR-qjMiLpyhXSiU74i-UTXpqf-N4',
      authDomain: 'kappaml.firebaseapp.com',
      projectId: 'kappaml',
      storageBucket: 'kappaml.appspot.com',
      messagingSenderId: '476967615457',
      appId: '1:476967615457:web:d371295953e71b53a970a0',
      measurementId: 'G-DKB4DSGGM1',
    };

export default !firebase.apps.length ? firebase.initializeApp(config) : firebase.app();

firebase.analytics();

export const GoogleAuthProvider = new firebase.auth.GoogleAuthProvider();
export const firebaseAuth = firebase.auth();

export const firestore = firebase.firestore();
if (isDev) firestore.useEmulator('localhost', 8081);
export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp;

export const functions = firebase.app().functions('europe-west3');
if (isDev) functions.useEmulator('localhost', 5001);
