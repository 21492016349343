<template>
  <div
    v-if="model"
    class="p-6"
  >
    <section class="flex flex-col justify-between md:flex-row">
      <h1 class="text-2xl font-semibold text-gray-900">
        {{ model.name }}
      </h1>
    </section>

    <section class="grid grid-cols-1 gap-4 mt-4 md:grid-cols-5">
      <div class="h-32 bg-white card">
        <div class="text-5xl">
          38
        </div>
        <div>events</div>
      </div>
      <div class="h-32 bg-white card">
        <div class="text-5xl">
          7
          <span class="text-2xl">/ 8</span>
        </div>
        <div>base models</div>
      </div>
      <div class="h-32 bg-white card">
        <div class="text-5xl">
          4
          <span class="text-2xl">/ 5</span>
        </div>
        <div>learning methods active</div>
      </div>
      <div class="h-32 bg-white card">
        <div class="text-5xl">
          94%
        </div>
        <div>accuracy</div>
      </div>
      <div class="h-32 bg-white card">
        <div class="text-5xl">
          88%
        </div>
        <div>Kappa</div>
      </div>
    </section>

    <section class="grid grid-cols-1 gap-4 mt-4 md:grid-cols-5">
      <div class="bg-white md:col-span-3 card">
        <div class="mb-2">
          Aggregated model activity
        </div>
        <ActivityLineChart :chart-data="activityChartData" />
      </div>
      <div class="bg-white md:col-span-2 card">
        <div class="mb-2">
          Learning methods
        </div>
        <MethodsRadarChart :chart-data="methodsRadarData" />
      </div>
    </section>

    <section class="my-4 ">
      <div class="flex items-center justify-between">
        <h2 class="text-2xl font-semibold">
          Events
        </h2>
      </div>
      <article
        v-for="event in events"
        :key="event.id"
        class="event"
      >
        <div
          class="flex items-center justify-between py-3"
          @click="$set(event, 'expanded', !event.expanded)"
        >
          <div class="w-6 mr-4">
            <Icon
              v-if="event.expanded"
              :path="mdiChevronUp"
            />
            <Icon
              v-else
              :path="mdiChevronRight"
            />
          </div>

          <div class="flex-auto">
            {{ event.type }}
          </div>
        </div>

        <transition
          enter-class="opacity-0"
          leave-class="opacity-0"
        >
          <div
            v-if="event.expanded"
            class="py-2 duration-200 border-t"
          >
            <code class="p-2 text-xs bg-gray-100">{{ event }}</code>
          </div>
        </transition>
      </article>
    </section>

    <section class="pt-8 mt-8 text-center border-t border-gray-300">
      <div
        class="font-semibold cursor-pointer"
        @click="deleteModel"
      >
        Delete model
      </div>
    </section>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { mdiChevronRight, mdiChevronUp } from '@mdi/js';
import { firestore } from '@/services/firebase';
import MethodsRadarChart from '@/components/charts/MethodsRadarChart';
import ActivityLineChart from '@/components/charts/ActivityLineChart';

export default {
  components: { MethodsRadarChart, ActivityLineChart },
  setup() {
    const model = ref(null);
    const user = ref(null);
    const events = ref([]);

    onMounted(() => {
      fetchModel();
    });

    const fetchModel = () => {
      firestore
        .collection('models')
        .doc(this.id)
        .get()
        .then(model => {
          if (model.exists) {
            model.data();
          } else {
            this.$router.replace({ name: 'overview' });
          }
        });
    };

    const deleteModel = () => {
      confirm('Are you sure you want to delete this model?') &&
        firestore
          .collection('models')
          .doc(this.id)
          .delete()
          .then(() => {
            this.$router.replace({ name: 'overview' });
          });
    };

    return { model, user, events, mdiChevronRight, mdiChevronUp, deleteModel };
  },
  computed: {
    methodsRadarData() {
      return {
        labels: ['Method 1', 'Method 2', 'Method 3', 'Method 4', 'Method 5'],
        datasets: [
          {
            label: 'Correctness',
            backgroundColor: 'rgb(19,162,173, 0.5)',
            borderColor: '#13A2AD',
            pointBackgroundColor: '#13A2AD',
            data: [0.6, 0.7, 0, 0.6, 0.5],
          },
        ],
      };
    },
    activityChartData() {
      return {
        labels: ['11-01', '12-01', '13-01', '14-01', '15-01'],
        datasets: [
          {
            label: 'Questions answered correctly',
            backgroundColor: 'rgb(19,162,173, 0.5)',
            data: [23, 20, 32, 13, 33],
          },
          {
            label: 'Questions displayed',
            data: [38, 22, 47, 20, 43],
          },
        ],
      };
    },
  },
};
</script>

<style lang="postcss" scoped>
.card {
  @apply p-6 rounded-lg shadow font-semibold;
}
.event {
  @apply p-4
  my-4
  bg-white
  font-semibold
  shadow
  rounded-lg
  cursor-pointer;
}
</style>
